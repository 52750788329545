import React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import { H1 } from "../components/Titles"
import Staff from "../containers/Staff"
import Planes from "../containers/PlaneCarousel"
export default () => (
  <Layout>
    <Helmet>
      <title>WorldLinx </title>
    </Helmet>
    <div
      css={css`
        width: 100%;
        height: 20em;
      `}
    >
      <Planes />
    </div>
    <Staff></Staff>
  </Layout>
)
